import { Service } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type ServiceInfo = InfoType<Service> & {
  id: Service;
};

export const standaloneServiceDetails: ServiceInfo[] = [
  {
    id: 'pro_medical',
    name: 'shared.serviceDetails.pro_medical.name',
    description: 'shared.serviceDetails.pro_medical.description'
  },
  {
    id: 'pro_fleet',
    name: 'shared.serviceDetails.pro_fleet.name',
    description: 'shared.serviceDetails.pro_fleet.description'
  },
  {
    id: 'pro_operator',
    name: 'shared.serviceDetails.pro_operator.name',
    description: 'shared.serviceDetails.pro_operator.description'
  }
];

export const serviceDetails: ServiceInfo[] = [
  ...standaloneServiceDetails,
  {
    id: 'celios_integration',
    name: 'shared.serviceDetails.celios_integration.name',
    description: 'shared.serviceDetails.celios_integration.description'
  },
  {
    id: 'dispolive_integration',
    name: 'shared.serviceDetails.dispolive_integration.name',
    description: 'shared.serviceDetails.dispolive_integration.description'
  },
  {
    id: 'moxi_operations',
    name: 'shared.serviceDetails.moxi_operations.name',
    description: 'shared.serviceDetails.moxi_operations.description'
  }
];
